import * as React from 'react';
import UtilityPanel from './utilityPanel';
import { useTranslation } from 'react-i18next';
import LocationPicker from './pickers/locationPicker';
import CourierPicker from './pickers/courierPicker';
import FunctionalButtonGroup from './buttons/functionals/functionalButtonGroup';
import InfoPage from './addressForm/infoPage';

const Landing = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className={'landing'}>
        <UtilityPanel />
        <div className={'head-text'}>{t('headText')}</div>
        <LocationPicker />
        <div
          className={'head-text'}
          style={{
            paddingBottom: '20px',
          }}
        >
          {t('postcard')}
        </div>
        <div className={'head-text'}>{t('via')}</div>
        <CourierPicker />
        {i18n.language === 'cn' && (
          <div className={'head-text'}>投递的明信片</div>
        )}
        <FunctionalButtonGroup />
      </div>

      <InfoPage />
    </>
  );
};

export default Landing;
