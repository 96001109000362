import React, { useContext } from 'react';
import { AppMachineContext } from '../../../../context/machineContext';
import { useActor } from '@xstate/react';
import { RiShuffleLine } from 'react-icons/ri';
import FunctionalButton from '../../../common/functionalButton/functionalButton';

function RandomButton() {
  const { locationMachine, courierMachine } = useContext(AppMachineContext);
  const [_, sendToLocationMachine] = useActor(locationMachine);
  const [__, sendToCourierMachine] = useActor(courierMachine);
  return (
    <FunctionalButton
      onClick={() => {
        sendToLocationMachine('random');
        sendToCourierMachine('random');
      }}
    >
      <RiShuffleLine />
    </FunctionalButton>
  );
}

export default RandomButton;
