import React, { useContext } from 'react';
import FunctionalButton from '../../../common/functionalButton/functionalButton';
import { HiPlus } from 'react-icons/hi';
import { AppMachineContext } from '../../../../context/machineContext';
import { useActor } from '@xstate/react';
import { courierKeys, locationKeys } from '../../../../data/constants';

function AddButton() {
  const { selectionMachine, courierMachine, locationMachine } =
    useContext(AppMachineContext);
  const [state, send] = useActor(selectionMachine);
  const [locationState] = useActor(locationMachine);
  const [courierState] = useActor(courierMachine);

  return (
    <FunctionalButton
      onClick={() => {
        send({
          type: 'add',
          payload: {
            courier: courierKeys[courierState.context.current],
            location: locationKeys[locationState.context.current],
          },
        });
      }}
    >
      <HiPlus color={state.matches('max') ? 'gainsboro' : undefined} />
    </FunctionalButton>
  );
}

export default AddButton;
