import React from 'react';
import { t } from 'i18next';
import styled from 'styled-components';
import FunctionalButton from '../common/functionalButton/functionalButton';
import { RiCloseLine } from 'react-icons/ri';

const SelectionCardContainer = styled.div`
  width: 100%;
  height: 10vh;
  background-color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const CardIndexSection = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Nunito;
  font-weight: 600;
  font-size: 1.5rem;

  padding-left: 1rem;
`;

const CardContentSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-left: auto;
  padding-left: 10%;
  //padding-right: 10px;
  font-family: Nunito;
  font-weight: 400;
  font-size: 2rem;
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
    //width: 80%;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  font-size: 2rem;
`;

function SelectionCard({
  index,
  location,
  courier,
  onRemove,
}: {
  index: number;
  location: string;
  courier: string;
  onRemove: any;
}) {
  return (
    <SelectionCardContainer>
      <CardIndexSection>{index + 1}</CardIndexSection>
      <CardContentSection>
        <div>{t('locations', { returnObjects: true })[location]}</div>
        <div>{t('couriers', { returnObjects: true })[courier]}</div>

        <CloseButtonContainer>
          <FunctionalButton onClick={onRemove}>
            <RiCloseLine />
          </FunctionalButton>
        </CloseButtonContainer>
      </CardContentSection>
    </SelectionCardContainer>
  );
}

export default SelectionCard;
