import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Toggle from '../../common/buttons/toggle';

const langLabels = ['中文', 'EN'];

const lang = ['cn', 'en'];

const ButtonContainer = styled.div`
  font-size: 20px;
  font-family: Nunito;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

function LangButton() {
  const { i18n } = useTranslation();
  const [nextLang, setNextLang] = useState(0);
  return (
    <ButtonContainer
      onClick={() => {
        i18n.changeLanguage(lang[nextLang]);
        setNextLang(nextLang ^ 1);
      }}
    >
      {langLabels[1]}
      <Toggle value={nextLang} />

      {langLabels[0]}
    </ButtonContainer>
  );
}

export default LangButton;
