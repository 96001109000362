import React, { useContext } from 'react';
import { courierKeys } from '../../../data/constants';
import ScrollablePickerRenderer from './scrollablePickerRenderer';
import { AppMachineContext } from '../../../context/machineContext';

function CourierPicker() {
  const { courierMachine } = useContext(AppMachineContext);
  return (
    <ScrollablePickerRenderer
      machine={courierMachine}
      candidates={courierKeys}
      localizationLabel={'couriers'}
    />
  );
}

export default CourierPicker;
