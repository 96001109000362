import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { t } from 'i18next';

const FieldText = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: Nunito;
  width: 100%;
`;

const AdditionalText = styled.span`
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  font-family: Nunito;
  width: 100%;
`;

const Field = styled.input`
  box-sizing: border-box;

  width: 100%;

  height: 4rem;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  font-family: Nunito;
  background-color: white;
  border-radius: 10px;

  outline: none;
  border: ${({ error }: { error?: any }) =>
    error !== undefined ? '2px solid tomato' : 'none'};
  &:focus {
    border: 3px solid lightblue;
  }
`;

function SingleField({
  label,
  optional = false,
  forwardRef,
  ...props
}: {
  label: string;
  optional?: boolean;
  error?: string;
  forwardRef: any;
}) {
  return (
    <>
      <FieldText>
        {label}
        {optional && <AdditionalText>{` ${t('optional')}`}</AdditionalText>}
      </FieldText>

      <Field ref={forwardRef} {...props} />
    </>
  );
}

export default SingleField;
