import React, { useContext } from 'react';
import { locationKeys } from '../../../data/constants';
import ScrollablePickerRenderer from './scrollablePickerRenderer';
import { AppMachineContext } from '../../../context/machineContext';

function LocationPicker() {
  const { locationMachine } = useContext(AppMachineContext);
  return (
    <ScrollablePickerRenderer
      candidates={locationKeys}
      localizationLabel={'locations'}
      machine={locationMachine}
    />
  );
}

export default LocationPicker;
