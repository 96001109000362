import React from 'react';
import styled from 'styled-components';
import SingleField from './field/singleField';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import FunctionalButton from '../../common/functionalButton/functionalButton';
import { FiSend } from 'react-icons/fi';
import { VscLoading } from 'react-icons/all';

const halfX = 100;
const halfY = 40;

const ActualFormContainer = styled.form`
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const SendButtonContainer = styled.div`
  display: flex;
  align-self: center;
  width: max-content;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: all 0.4s;
`;

const LoadingContainer = styled.div`
  animation-name: rotate;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// animation-name: ${({ isLoading = false }: { isLoading?: boolean }) =>
//   isLoading ? 'toRightBorder, full, back' : undefined};

type FieldKey =
  | string
  | {
      name: string;
      optional?: boolean;
    };

const formKeys: Array<FieldKey> = [
  'name',
  'firstLine',
  {
    name: 'secondLine',
    optional: true,
  },
  'city',
  'stateOrProvince',
  'zipCode',
  'country',
];

function FormAssembler({
  handleData,
  disabled,
  loading = false,
}: {
  handleData: any;
  disabled: boolean;
  loading?: boolean;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  return (
    <ActualFormContainer>
      {formKeys.map(key => {
        const _key = typeof key !== 'string' ? key['name'] : key;
        const required: boolean =
          typeof key !== 'string' ? !key['optional'] : true;

        const { ref, ...props } = register(_key, { required });
        return (
          <SingleField
            key={_key}
            label={t(_key)}
            error={errors[_key]}
            optional={!required}
            forwardRef={ref}
            {...props}
          />
        );
      })}

      <SendButtonContainer>
        <FunctionalButton onClick={() => handleSubmit(handleData)()}>
          {loading ? (
            <LoadingContainer>
              <VscLoading size={80} color={'gainsboro'} />
            </LoadingContainer>
          ) : (
            <FiSend
              color={
                disabled || Object.keys(errors).length !== 0
                  ? 'gainsboro'
                  : undefined
              }
            />
          )}
        </FunctionalButton>
      </SendButtonContainer>
    </ActualFormContainer>
  );
}

export default FormAssembler;
