import React from 'react';
import ScrollablePicker from '../../common/scrollablePicker/scrollablePicker';
import { t } from 'i18next';
import { Interpreter } from 'xstate';

function ScrollablePickerRenderer({
  candidates,
  localizationLabel,
  machine,
}: {
  candidates: Array<string>;
  localizationLabel: string;
  machine: Interpreter<any, any, any, any, any>;
}) {
  return (
    <div className={'scroll-picker-container'}>
      <ScrollablePicker
        size={8}
        candidates={candidates}
        labels={t(localizationLabel, { returnObjects: true })}
        machine={machine}
      />
    </div>
  );
}

export default ScrollablePickerRenderer;
