import React from 'react';
import styled from 'styled-components';

const KnobContainer = styled.div`
  position: absolute;
  left: 60px;
  top: 27px;
  transition: all 0.4s;
  transform: translateX(${({ value }: { value: number }) => value * 18}px);
  overflow: hidden;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 3px solid black;
  border-radius: 20px;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40px;
  width: 50px;
  margin: 0 10px;
`;

const TopBox = styled.div`
  width: 100%;
  height: 18px;
  border-bottom: 3px solid black;
  position: relative;
`;

function Toggle({ value }: { value: number }) {
  return (
    <ToggleContainer>
      <TopBox />
      <KnobContainer value={value} />
    </ToggleContainer>
  );
}

export default Toggle;
