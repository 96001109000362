import React, { useContext } from 'react';
import i18n, { t } from 'i18next';
import { SelectionType } from '../../../machines/selectionMachine';
import SelectionCard from '../selectionCard';
import FormAssembler from './formAssembler';
import styled from 'styled-components';
import { useActor } from '@xstate/react';
import { AppMachineContext } from '../../../context/machineContext';

const SectionTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Nunito;
  font-size: 30px;
  padding-bottom: 20px;
  padding-top: 40px;
  font-weight: 800;
`;

const SmallerSectionTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Nunito;
  font-size: 20px;
  font-style: italic;
  padding-bottom: 20px;
  //padding-top: 10px;
  font-weight: 400;
`;

const YayContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Content() {
  const { selectionMachine, postMachine } = useContext(AppMachineContext);
  const [state, send] = useActor(selectionMachine);
  const [postMachineState, sendToPost] = useActor(postMachine);

  if (postMachineState.value === 'done') {
    return <YayContainer className={'head-text'}>yay!</YayContainer>;
  }

  return (
    <>
      <SectionTitleContainer>{t('selections')}</SectionTitleContainer>
      <SmallerSectionTitleContainer>
        {t('selectionLimits')}
      </SmallerSectionTitleContainer>
      {state.context.selections.map(
        (selection: SelectionType, index: number) => (
          <SelectionCard
            key={index + ''}
            index={index}
            {...selection}
            onRemove={() =>
              send({
                type: 'remove',
                index,
              })
            }
          />
        )
      )}
      <SectionTitleContainer>{t('address')}</SectionTitleContainer>
      <SmallerSectionTitleContainer>
        {t('addressNotice')}
      </SmallerSectionTitleContainer>
      <FormAssembler
        loading={postMachineState.matches('loading')}
        disabled={state.matches('unselected')}
        handleData={(formData: any) => {
          const payload = {
            ...formData,
            lang: i18n.language,
            selections: state.context.selections,
          };

          if (!state.matches('unselected')) {
            sendToPost({
              type: 'post',
              payload,
            });
          }
        }}
      />

      <SmallerSectionTitleContainer
        style={{
          justifyContent: 'center',
        }}
      >
        {postMachineState.context.error || ' '}
      </SmallerSectionTitleContainer>
    </>
  );
}

export default Content;
