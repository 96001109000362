import React from 'react';
import LangButton from './buttons/langButton';

function UtilityPanel() {
  return (
    <div className={'utility-panel-container'}>
      <LangButton />
    </div>
  );
}

export default UtilityPanel;
