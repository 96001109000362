import React from 'react';
import styled from 'styled-components';
import { FaChevronUp } from 'react-icons/fa';
import { useActor } from '@xstate/react';
import { Interpreter } from 'xstate';

type DirectionType = 'vertical' | 'horizontal';

type PickerContainerProps = {
  direction?: DirectionType;
};

type ChoiceRenderCommonProps = {
  // height: number;
  direction: DirectionType;
  current: number;
  size: number;
};

type ScrollablePickerProps = {
  size: number;
  candidates: Array<string>;
  labels: {
    [key: string]: string;
  };
  initialIndex?: number;
  machine: Interpreter<any, any, any, any, any>;
} & PickerContainerProps;

const smallScreenCommonWidthUnit = 70;
const largerScreenCommonWidthUnit = 65;
const heightScaleUnit = 2;

const PickerContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction = 'horizontal' }: PickerContainerProps) =>
    direction === 'vertical' ? 'horizontal' : 'row'};
  justify-content: center;
  align-items: center;
  width: 90%;
`;

const ChoiceWindow = styled.div`
  position: relative;
  @media only screen and (max-width: 414px) {
    width: ${smallScreenCommonWidthUnit}vw;
  }

  width: ${largerScreenCommonWidthUnit}vw;

  height: ${({ size }: { size: number }) => size * heightScaleUnit}rem;
  overflow: hidden;
`;

const ChoicesOuterContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  transition: ease 1s;
  height: ${({ size }: { size: number }) => size * heightScaleUnit}rem;

  @media only screen and (max-width: 414px) {
    transform: ${({ current, direction, size }: ChoiceRenderCommonProps) =>
      direction === 'horizontal'
        ? `translate( -${current * smallScreenCommonWidthUnit}vw, 0)`
        : `translate(0, -${current * size}rem)`};
  }

  transform: ${({ current, direction, size }: ChoiceRenderCommonProps) =>
    direction === 'horizontal'
      ? `translate( -${current * largerScreenCommonWidthUnit}vw, 0)`
      : `translate(0, -${current * size}rem)`};
`;

type ChoicesHorizontalInnerContainer = {
  candidates: ScrollablePickerProps['candidates'];
  size: ScrollablePickerProps['size'];
};

const ChoicesHorizontalInnerContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: ${({ candidates }: ChoicesHorizontalInnerContainer) =>
    `repeat(${candidates.length}, 1fr)`};
  //height: ${({ size }: ChoicesHorizontalInnerContainer) => size}rem;
  height: ${({ size }: { size: number }) => size * heightScaleUnit}rem;

  @media only screen and (max-width: 414px) {
    width: ${({ candidates }: ChoicesHorizontalInnerContainer) =>
      `${candidates.length * smallScreenCommonWidthUnit}vw`};
  }
  width: ${({ candidates }: ChoicesHorizontalInnerContainer) =>
    `${candidates.length * largerScreenCommonWidthUnit}vw`};
`;

const ENABLED_COLOR = 'black';
const DISABLED_COLOR = 'gainsboro';

const ChoiceText = styled.div`
  font-family: Nunito;
  font-weight: 800;
  font-size: ${({ height }: { height: number }) => height / 2}rem;
  //height: ${({ height }: { height: number }) => height - 2}rem;

  width: ${largerScreenCommonWidthUnit}vw;
  @media only screen and (max-width: 414px) {
    font-size: 2.5rem;
    width: ${smallScreenCommonWidthUnit}vw;
  }

  @media only screen and (max-width: 630px) and (min-width: 415px) {
    font-size: 3rem;
  }
  text-align: center;
`;

const ChevronContainer = styled.a`
  transition: color 0.8s;
`;

function ScrollablePicker({
  size,
  candidates,
  direction = 'horizontal',
  labels,
  machine,
}: ScrollablePickerProps) {
  const [state, send] = useActor(machine);
  return (
    <PickerContainer direction={direction}>
      <ChevronContainer
        onClick={e => {
          e.preventDefault();
          send('increment');
        }}
      >
        <FaChevronUp
          size={40}
          style={{
            transition: 'color 0.6s',
            transform:
              direction === 'horizontal' ? 'rotate(-90deg)' : undefined,
          }}
          color={
            state.matches('increment.disabled') ? DISABLED_COLOR : ENABLED_COLOR
          }
        />
      </ChevronContainer>
      <ChoiceWindow size={size}>
        <ChoicesOuterContainer
          size={size}
          current={state.context.current}
          direction={direction}
        >
          <ChoicesHorizontalInnerContainer candidates={candidates} size={size}>
            {candidates.map(cand => (
              <ChoiceText key={cand} height={size}>
                {labels[cand]}
              </ChoiceText>
            ))}
          </ChoicesHorizontalInnerContainer>
        </ChoicesOuterContainer>
      </ChoiceWindow>
      <ChevronContainer
        onClick={e => {
          e.preventDefault();
          send('decrement');
        }}
      >
        <FaChevronUp
          size={40}
          style={{
            transition: 'color 0.6s',
            transform:
              direction === 'horizontal' ? 'rotate(90deg)' : 'rotate(180deg)',
          }}
          color={
            state.matches('decrement.disabled') ? DISABLED_COLOR : ENABLED_COLOR
          }
        />
      </ChevronContainer>
    </PickerContainer>
  );
}

export default ScrollablePicker;
