import React, { useContext } from 'react';
import { AppMachineContext } from '../../../context/machineContext';
import { useActor } from '@xstate/react';
import styled from 'styled-components';
import Content from './content';

const FormContainer = styled.div`
  background-color: whitesmoke;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
`;

const FormInnerContainer = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 5%;
  align-items: center;

  width: 40vw;

  @media only screen and (min-width: 800px) and (max-width: 1300px) {
    width: 65vw;
  }

  @media only screen and (max-width: 800px) {
    width: 80vw;
  }
`;

function InfoPage() {
  const { selectionMachine } = useContext(AppMachineContext);
  const [state] = useActor(selectionMachine);

  if (!state.context.showAddressForm) {
    return null;
  }

  return (
    <FormContainer className={'address-form'}>
      <FormInnerContainer>
        <Content />
      </FormInnerContainer>
    </FormContainer>
  );
}

export default InfoPage;
