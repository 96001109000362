import React, { useContext } from 'react';
import { IoList } from 'react-icons/io5';
import FunctionalButton from '../../../common/functionalButton/functionalButton';
import { AppMachineContext } from '../../../../context/machineContext';
import { useActor } from '@xstate/react';
import styled from 'styled-components';

const NumberIndicator = styled.span`
  color: lightblue;
  position: absolute;
  top: -20px;
  right: -1.5rem;
  font-family: Nunito;

  @media only screen and (max-width: 430px) {
    font-size: 1rem;
    right: -1.6rem;
  }

  font-size: 2rem;

  font-weight: 800;
`;

function ListButton() {
  const { selectionMachine } = useContext(AppMachineContext);
  const [state, send] = useActor(selectionMachine);
  return (
    <FunctionalButton
      onClick={() => {
        send('show');
        setTimeout(() => {
          document
            .querySelector('.address-form')
            ?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }}
    >
      <IoList color={state.value === 'unselected' ? 'lightgrey' : undefined} />
      {state.context.selections.length !== 0 && (
        <NumberIndicator>
          {`${state.value === 'max' ? 'max:' : ''}` +
            state.context.selections.length}
        </NumberIndicator>
      )}
    </FunctionalButton>
  );
}

export default ListButton;
