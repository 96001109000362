import React from 'react';
import styled from 'styled-components';
const FunctionalButton = styled.a`
  position: relative;
  transition: color 0.2s;
  &:hover {
    color: lightblue;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 440px) {
    font-size: 3rem;
  }
  font-size: 4rem;
`;

export default FunctionalButton;
