import React from 'react';
import RandomButton from './randomButton';
import AddButton from './addButton';
import ListButton from './listButton';
import styled from 'styled-components';

const GroupOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding: 20px 0px;
`;

const GroupInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  @media only screen and (max-width: 430px) {
    width: 80%;
  }
`;

function FunctionalButtonGroup() {
  return (
    <GroupOuter>
      <GroupInner>
        <RandomButton />
        <AddButton />
        <ListButton />
      </GroupInner>
    </GroupOuter>
  );
}

export default FunctionalButtonGroup;
