import * as React from 'react';
import { Helmet } from 'react-helmet';
import Landing from '../components/landing/landing';

// markup
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Request A Postcard!</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Hubballi&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Landing />
    </>
  );
};

export default IndexPage;
